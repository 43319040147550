import React from "react"
import {graphql} from "gatsby"
import {GatsbySeo} from 'gatsby-plugin-next-seo';
import MainLayout from "../components/mainLayout/MainLayout"
import PostEntry from "../components/postEntry/PostEntry"
import BreadCrumb from "../components/breadCrumb/BreadCrumb"
import config from "../config/config"
import Pager from "../components/pager/Pager";

const CategoryList = ({pageContext, data}) => {
    const {category} = pageContext
    const {allMarkdownRemark, site} = data
    const siteTitle = site.siteMetadata.title || ''
    const siteAuthor = site.siteMetadata.author || ''
    const siteBaseUrl = site.siteMetadata.siteUrl || ''
    const categoryName = category.replace(/^\w/, c => c.toUpperCase())
    const navCategory = config?.nav.find(e => e.title === categoryName)
    const canonical = `${siteBaseUrl}${navCategory.href}`
    const transformedLocale = config.meta.locale.toLowerCase().replace("_", "-")
    // TODO: add category image
    const image = `${siteBaseUrl}${allMarkdownRemark.edges[0].node.frontmatter.featuredImage.publicURL}`

    return (
        <MainLayout activeItem={`/${category}`}>
            <GatsbySeo
                title={categoryName}
                titleTemplate={`Artículos sobre %s | ${siteTitle}`}
                description={navCategory.description}
                canonical={canonical}
                language={config.meta.lang}
                metaTags={[
                    {property: 'author', name: 'author', content: siteAuthor},
                    {property: 'copyright', name: 'copyright', content: siteAuthor},
                    {property: 'publisher', name: 'publisher', content: siteAuthor},
                    {property: 'content-language', name: 'content-language', content: transformedLocale}
                ]}
                openGraph={{
                    title: `Artículos sobre ${categoryName} | ${siteTitle}`,
                    description: navCategory.description,
                    url: canonical,
                    type: 'website',
                    images: [
                        {
                            url: image,
                            width: 1200,
                            height: 630,
                            alt: `Artículos sobre ${categoryName} | ${siteTitle}`,
                        },
                    ],
                }}
                twitter={{
                    site: siteAuthor,
                    handle: siteAuthor,
                    cardType: 'summary_large_image'
                }}
            />

            <BreadCrumb category={category}/>
            <h1>Artículos sobre {categoryName}</h1>
            <p>{navCategory.description}</p>
            <hr/>
            {
                allMarkdownRemark.edges.map(({node}) => (
                    <PostEntry
                        key={node.frontmatter.title}
                        title={node.frontmatter.title}
                        href={`/${category}/${node.frontmatter.slug}`}
                        image={node.frontmatter.thumb}
                        excerpt={node.frontmatter.excerpt}
                        os={node.frontmatter.os}
                        dificultad={node.frontmatter.dificultad}
                    />
                ))
            }
            <Pager pageContext={ pageContext }/>
        </MainLayout>
    )
}

export const query = graphql`
    query CategoryListQuery(
        $ids: [String]!,
        $skip: Int!,
        $limit: Int!
    ) {
        allMarkdownRemark(
            filter: { id: { in: $ids } },
            sort: {
                fields: [frontmatter___date],
                order: DESC
            },
            skip: $skip,
            limit: $limit
        ) {
            edges {
                node {
                    frontmatter {
                        slug
                        tags
                        thumb
                        title
                        os
                        dificultad
                        excerpt
                        description
                        date(formatString: "MMM D, YYYY")
                        featuredImage {
                            publicURL
                            childImageSharp {
                                fluid {
                                    sizes
                                    srcSet
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                siteUrl
                author
            }
        }
    }
`

export default CategoryList
