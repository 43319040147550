import React from 'react';
import { Link } from 'gatsby';
import { Icon } from '@iconify/react';
import "./Pager.scss"

const Pager = ({ pageContext }) => {
    const { previousPagePath, nextPagePath } = pageContext;
    return (
        <nav className="pager_nav">
            <div className="paginate-button new-posts-container">
                {previousPagePath && (
                    <Link to={previousPagePath} title="Posts nuevos" alt="Posts nuevos">
                        <button>
                            <Icon icon="bxs:left-arrow" />
                            <span>Posts nuevos</span>
                        </button>
                    </Link>
                )}
            </div>

            <div className="paginate-button old-posts-container">
                {nextPagePath && (
                    <Link to={nextPagePath} title="Posts antiguos" alt="Posts antiguos">
                        <button>
                            <span>Posts antiguos</span>
                            <Icon icon="bxs:right-arrow" />
                        </button>
                    </Link>
                )}
            </div>
        </nav>
    );
};

export default Pager;
