import React from "react"
import PropTypes from "prop-types"
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { graphql, Link, useStaticQuery } from "gatsby"
import "./BreadCrumb.scss"

const BreadCrumb = ({ category, post }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
        `
    )

    const siteUrl = site.siteMetadata?.siteUrl || ''
    const categoryName = category.replace(/^\w/, c => c.toUpperCase())
    const breadcrumbJsonLD = [
        {
            position: 1,
            name: 'Home',
            item: siteUrl,
        },
        {
            position: 2,
            name: categoryName,
            item: `${siteUrl}/${category}`
        }
    ]

    if(post) {
        breadcrumbJsonLD.push({
            position: 3,
            name: post.title,
            item: `${siteUrl}/${category}/${post.slug}`,
        })
    }

    return (
        <>
            <div className="breadcrumb">
                <p>
                    <span>
                        <Link to="/" title="Home">Home</Link>
                    </span>
                    <span className="separator">>></span>
                    <span>
                        { post && <Link to={`/${category}/`} title={ categoryName }>{ categoryName }</Link> }
                        { !post && categoryName }
                    </span>
                    {
                        post && <>
                            <span className="separator">>></span>
                            <span>{ post.title }</span>
                        </>
                    }
                </p>
            </div>
            <BreadcrumbJsonLd itemListElements={ breadcrumbJsonLD } />
        </>
    )
}

BreadCrumb.propTypes = {
    category: PropTypes.string.isRequired,
    post: PropTypes.object
}

export default BreadCrumb
